<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="primarylog">
        <heade></heade>
        <div class="con">
            <div class="box">
                <div class="tit">
                    <P class="p1">主管理员设置</P>
                    <!-- <P class="p2">未收到短信？</P> -->
                </div>
                <p class="p3">企业账号登录</p>
                <ul>
                    <li>
                        <p><span>*</span>企业管理账号</p>
                        <input type="text" placeholder="请输入统一社会信用代码" @keyup.enter='queding' v-model="phone">
                    </li>
                    <li>
                        <p><span>*</span>密码</p>
                        <input :type="mima" placeholder="请输入密码" @keyup.enter='queding' v-model="password">
                        <img class="img1" src="../../assets/Stroken2.png" alt="">
                        <i @click="yanjing"><img :src="suo?require('../../assets/eye-no.png'):require('../../assets/eye.png')" alt=""></i>
                    </li>
                </ul>
                <p class="p4" @click="queding">确认</p>
                <router-link to="/fgtrgs">忘记密码</router-link>
            </div>
        </div>
        <!-- <div class="cone" v-if='!xian'>
            <div class="box">
                <p class="p1">设置主管理员</p>
                <p class="p2">添加一个主管理员</p>
                <div class="div1"><span @click="tianjia_zhuguanli">添加主管理员</span></div>
                <div class="div2" v-if="ming">
                    <p>管理员姓名</p>
                    <p>手机号</p>
                    <p>查看权限</p>
                    <p>编辑</p>
                </div>
                <div class="div3" v-if="ming">
                    <p>{{name1}}</p>
                    <p>{{phone1}}</p>
                    <p>全部</p>
                    <p style="color:#F16977;cursor:pointer;" @click='bianjizhu'>编辑</p>
                </div>
                <p style="color:#888;font-size:0.12rem;text-align:center;margin-top:1rem;" v-if="!ming">您还未拥有主管理员，快去添加吧</p>
            </div>
        </div> -->
        <foote></foote>
    </div>
</template>

<script>
import { ent_acct_login, query_ent_primary_manager } from '../../api/api.js'
import heade from '../../components/Heade'
import foote from '../../components/Foote'
export default {
  name: 'primarylog',
  data () {
    return {
      mima: 'password',
      suo: true,
      phone: '',
      password: '',
      ent_id: '',
      xian: '',
      ming: false
      // name1:'',
      // phone1:'',
      // name2:'',
      // phone2:'',
      // quanli:''
    }
  },
  created () {
    // let that=this
    // document.onkeydown=function(e){
    //   let key=window.event.keyCode;
    //   if(key==13){
    //     that.queding();
    //   }
    // }
    // if (sessionStorage.getItem("pc_mlbb_ent_info") != null&&sessionStorage.getItem("pc_mlbb_ent_info") != undefined&&sessionStorage.getItem("pc_mlbb_ent_info") != '') {
    //     this.ent_id =JSON.parse(sessionStorage.getItem("pc_mlbb_ent_info")).ent_id;
    // }
    // if (sessionStorage.getItem("pc_mlbb_ent_flag") != null&&sessionStorage.getItem("pc_mlbb_ent_flag") != undefined&&sessionStorage.getItem("pc_mlbb_ent_flag") != '') {
    //     this.quanli =JSON.parse(sessionStorage.getItem("pc_mlbb_ent_flag"));
    // }
    // if(this.ent_id!=''){
    //     this.jichu()
    //     this.xian=false
    // }else{
    //     this.xian=true
    // }
    // this.jichu()
  },
  components: { heade, foote },
  methods: {
    // jichu(){
    //     query_ent_primary_manager({data:{
    //         ent_id:this.ent_id
    //     }}).then(res=>{
    //         console.log(res)
    //         if(res.data.code==10103){
    //             if(JSON.parse(res.data.body.data)!=null){
    //                 let xin=JSON.parse(res.data.body.data)
    //                 console.log(xin)
    //                 if(xin.p_mng_name!=undefined&&xin.p_mng_name!=null){
    //                     this.ming=true
    //                     this.name1=xin.p_mng_name;
    //                     this.phone1=xin.p_mng_mobile;
    //                     this.name2=xin.p_mng_name;
    //                     this.phone2=xin.p_mng_mobile;
    //                 }else{
    //                     this.ming=false
    //                 }
    //             }else{
    //                this.ming=false
    //             }
    //         }else if(res.data.code==10104){
    //             this.$message({
    //                 message: '获取失败',
    //                 type: 'error'
    //             });
    //         }
    //     })
    // },
    yanjing () {
      this.suo = !this.suo
      if (this.suo) {
        this.mima = 'password'
      } else {
        this.mima = 'text'
      }
    },
    //   确定
    queding () {
      if (this.phone.length == 18 && this.password.length >= 6) {
        ent_acct_login({
          data: {
            ent_acct: this.phone,
            ent_pwd: this.password
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10017) {
            this.$message({
              message: '登录成功',
              type: 'success'
            })
            this.$router.push('/master')
            sessionStorage.setItem('pc_ent_id', JSON.stringify(res.data.body.data))
          } else if (res.data.code == 10088) { // 第一次登陆需要修改密码
            this.$message({
              message: '登录成功,进入修改密码页',
              type: 'success'
            })
            sessionStorage.setItem('pc_mlbb_phone', JSON.stringify(this.phone))
            this.$router.push('/resetpwd')
          } else if (res.data.code == 10036) {
            this.$message({
              message: '密码错误',
              type: 'error'
            })
          }
        })
      } else {
        this.$message({
          message: '请填写完整信息',
          type: 'warning'
        })
      }
    },
    // 添加主管理员
    tianjia_zhuguanli () {
      if (this.name1 == '' && this.phone1 == '') {
        if (this.quanli == 1) {

        } else {

        }
      } else {
        this.$message({
          message: '只能添加一个主管理员哦~',
          type: 'warning'
        })
      }
    },
    // 编辑注管理员
    bianjizhu () {

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.primarylog {
    background:#E7E8EB;
    min-height: 100vh;
    .con{
        width:12rem;
        margin: 0 auto;
        padding-top:1.83rem;
        margin-bottom:1.41rem;
        .box{
            height:5rem;
            width:12rem;
            background:#fff;
            .tit{
                height:0.7rem;
                background:#F5F5F5;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 0.5rem;
                .p1{
                    font-size: 0.22rem;
                    color:#676669;
                }
                .p2{
                    font-size: 0.14rem;
                    color:#468DFF;
                    cursor:pointer;
                }
            }
            .p3{
                font-size: 0.16rem;
                color:#676669;
                text-align: center;
                margin-top:0.26rem;
                margin-bottom: 0.32rem;
            }
            ul{
                li{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 0.4rem;
                    position: relative;
                    p{
                        width:1.3rem;
                        text-align: right;
                        font-size: 0.16rem;
                        color:#676669;
                        margin-right:0.1rem;
                        span{
                            font-size: 0.16rem;
                            color:#F96C6C;
                        }
                    }
                    input{
                        width:1.99rem;
                        height:0.35rem;
                        border:0.01rem solid #DDDDDD;
                        border-radius: 0.06rem;
                        padding:0 0.45rem;

                    }
                    .img1{
                        height:0.2rem;
                        width:0.18rem;
                        position: absolute;
                        left:5.3rem;
                    }
                    i{
                        padding: 0 0.1rem 0 0.14rem;
                        border-left: 0.01rem solid #ddd;
                        display: flex;
                        align-items: center;
                        position: absolute;
                        right:4rem;
                        top:0.11rem;
                        img{
                            height:0.16rem;
                            width:0.2rem;
                        }
                    }
                }
            }
            .p4{
                width:2.86rem;
                height:0.38rem;
                background:#8d52e3;
                border-radius:0.06rem;
                color:#FFFFFF;
                font-size: 0.16rem;
                line-height: 0.38rem;
                text-align: center;
                margin-left:5.17rem;
                margin-top:0.88rem;
                cursor:pointer;
            }
            a{
                color:#75C1FF;
                font-size: 0.14rem;
                // text-align: center;
                display: block;
                margin-top: 0.24rem;
                margin-left:6.3rem;
            }
        }
    }
    .cone{
        width:12rem;
        margin: 0 auto;
        padding-top:1.83rem;
        margin-bottom:1.41rem;
        .box{
            height:5rem;
            width:11.12rem;
            background:#fff;
            margin:0 auto;
            padding: 0 0.44rem;
            .p1{
                font-size: 0.18rem;
                color:#1A2533;
                font-weight: 500;
                padding-top:0.19rem;
                margin-bottom: 0.08rem;
            }
            .p2{
                font-size: 0.12rem;
                color:#A9A9A9;
                margin-bottom: 0.11rem;
            }
            .div1{
                height:0.42rem;
                width:100%;
                background-color: #E7E8EB;
                display: flex;
                align-items: center;
                span{
                    height: 0.25rem;
                    width:0.98rem;
                    background: #fff;
                    border:0.01rem solid #A9A9A9;
                    font-size: 0.12rem;
                    color:#888888;
                    line-height: 0.25rem;
                    text-align: center;
                    display: block;
                    margin-left: 0.22rem;
                    cursor:pointer;
                }
            }
            .div2{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 0.25rem;
                margin-top:0.25rem;
                margin-bottom: 0.07rem;
                p{
                    font-size: 0.12rem;
                    color:#3C3A3E;
                }
            }
            .div3{
                border-top:0.01rem solid #eee;
                border-bottom: 0.01rem solid #eee;
                display: flex;
                align-items: center;
                justify-content: space-between;
                p{
                    padding: 0.25rem;
                    font-size: 0.12rem;
                    color:#4C4A4D;
                }
            }
        }
    }
}
@media screen and (max-width: 12rem) {
  .primarylog {
    width:12rem;
  }
}
</style>
